import {registerInitializer} from "../shared/utils";

export const initializeCarousel = () => {
  var fastCarousel = $(".carousel.carousel-fast");
  fastCarousel.carousel({
    interval: 3000,
    ride: 'carousel'
  });

  var slowCarousel = $(".carousel.carousel-slow");
  slowCarousel.carousel({
    interval: 6000,
    ride: 'carousel'
  });
};

registerInitializer(initializeCarousel);
