import {registerInitializer} from "../shared/utils";

export const initializeHeader = () => {
  window.onscroll = function() {
    if ($(document).scrollTop() > 90) {
      $('.logo-header').addClass('small');
    } else {
      $('.logo-header').removeClass('small');
    }

    if ($(document).scrollTop() > 210) {
      $('.main-navbar').addClass('white-bg');
      $('#sidebar').addClass('no-border-radius');
    } else {
      $('.main-navbar').removeClass('white-bg');
      $('#sidebar').removeClass('no-border-radius');
    }

    return;
  };
};

registerInitializer(initializeHeader);
