import {registerInitializer} from "../shared/utils";

// import "malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.js"
// import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css';

export const initializeNavigationSlider = () => {
  // $('#sidebarCollapse').mCustomScrollbar({
  //   theme: 'minimal'
  // });

  $('.home .quicklinks a').on('click', function(event) {
    event.preventDefault();
    var element = $(this).attr("href");
    $(document).scrollTo($(element).offset().top - 70, 500);
  });

  $('.main-navigation .contact').on('click', function(event) {
    event.preventDefault();
    var element = $(this).attr("href");
    $(document).scrollTo($(element).offset().top - 80, 500);
  });

  $('#swissmap').on('click', function(event) {
    event.preventDefault();
    $(document).scrollTo($('#location').offset().top - 50, 500);
  });

  $('#007-intro').on('click', function(event) {
    event.preventDefault();
    var element = $(this).attr("href");
    $(document).scrollTo($(element).offset().top - 50, 500);
  });

  $('#sidebarCollapse').on('click', function() {
    if($('#sidebar').hasClass('active')) {
      $('#sidebar').removeClass('active');
      $('.overlay').removeClass('active');
      $('.menu-toggle').removeClass('menu-active');
    } else {
      $('#sidebar').addClass('active');
      $('.overlay').addClass('active');
      $('.menu-toggle').addClass('menu-active');
    }
  });

  $('.main-navigation .icon-select-language').on('click', function() {
    if($('#sidebar').hasClass('active')) {
      $('#sidebar').removeClass('active');
      $('.overlay').removeClass('active');
      $('.menu-toggle').removeClass('menu-active');
    }
  });
};

registerInitializer(initializeNavigationSlider);
