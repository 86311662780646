var showMap;

showMap = function() {
  var defaultPosition, infowindow, lat, lng, map, marker, markerPosition;
  if ($('#map').length > 0) {
    lat = parseFloat($('#map').data('lat'));
    lng = parseFloat($('#map').data('lng'));

    defaultPosition = {
      lat: lat,
      lng: lng
    };
    map = new google.maps.Map(document.getElementById('map'), {
      mapId: "a2269b7e064a35d6",
      zoom: 15,
      center: defaultPosition,
      mapTypeControlOptions: {
        mapTypeIds: [],
        mapTypeId: 'satellite'
      },
      streetViewControl: false,
      disableDefaultUI: true
    });

    markerPosition = new google.maps.LatLng(lat, lng);

    marker = new google.maps.Marker({
      map: map,
      position: markerPosition,
      draggable: false
    });

    infowindow = new google.maps.InfoWindow({
      content: $('#map').data('text')
    });

    marker.addListener('click', function() {
      infowindow.open(map, marker);
    });

    google.maps.event.addDomListener(map, 'click', function() {
      infowindow.close();
      infowindow2.close();
      return infowindow3.close();
    });
  }
};

window.showMap = showMap;

$(document).ready(showMap);

$(document).on("turbolinks:load", showMap);
