import {registerInitializer} from "../shared/utils";

export const initializeBackStretchHandler = () => {
  $("#intro .arrow-down").on('click', function() {
    event.preventDefault();
    var element = $(this).attr("href");
    $(document).scrollTo($(element).offset().top - 70, 500);
  });
};

export const initializeToolTipInMap = () => {
  $('#swissmap .point').on('mouseenter mouseout', function(event) {
    event.preventDefault();
    $('#swissmap .position').toggle();
  });
};

registerInitializer(initializeBackStretchHandler);
registerInitializer(initializeToolTipInMap);
